import { Menu, MenuItem } from './globalNavClasses.js';

export function initializeMenu(menuElement) {
  const menus = [];
  const mainMenu = crawlMenu(menuElement.id, menuElement, menus);
  return { mainMenu, menus };
}

export function initializeMicrositeMenu(menuElement) {
  const menus = [];
  const mainMenu = crawlMenu('microsite-menu', menuElement, menus);
  return { mainMenu, menus };
}

function crawlMenu(menuType, menuElement, menus) {
  const preItems = [];
  const items = [];
  const postItems = [];
  Array.from(menuElement.parentElement.children).forEach((child) => {
    if (child.classList.contains('offcanvas-header')) {
      Array.from(child.children).forEach((grandChild) => {
        if (grandChild.classList.contains('menu-close-btn')) {
          preItems.push(new MenuItem(grandChild));
        }
      });
    }
    if (child.classList.contains('menu-back-btn')) {
      preItems.push(new MenuItem(child));
    }
    if (child.tagName === 'DIV' && child.classList.contains('menu-filter')) {
      Array.from(child.children).forEach((grandChild) => {
        if (grandChild.tagName === 'DIV') {
          Array.from(grandChild.children).forEach((greatGrandChild) => {
            if (greatGrandChild.tagName === 'INPUT') {
              preItems.push(new MenuItem(greatGrandChild));
            }
          });
        }
      });
    }
    if (child.tagName === 'DIV' && !child.classList.contains('offcanvas-header')) {
      Array.from(child.children).forEach((grandChild) => {
        if (grandChild.tagName === 'A' || grandChild.tagName === 'BUTTON') {
          postItems.push(new MenuItem(grandChild));
        }
      });
    }
    if (child.tagName === 'DIV' && child.classList.contains('cta-area')) {
      Array.from(child.querySelectorAll('a, button')).forEach((grandChild) => {
        postItems.push(new MenuItem(grandChild));
      });
    }
  });
  Array.from(menuElement.children).forEach((child) => {
    if (child.tagName === 'LI') {
      const anchorsOrButtons = Array.from(child.children).filter((el) => el.tagName === 'A' || el.tagName === 'BUTTON');
      const nestedUl = child.querySelector('ul');
      anchorsOrButtons.forEach((anchorOrButton) => {
        const nestedMenu =
          nestedUl && anchorOrButton.tagName === 'BUTTON' ? crawlMenu(getMenuLevel(nestedUl), nestedUl, menus) : null;
        items.push(new MenuItem(anchorOrButton, nestedMenu));
      });
    }
  });
  let firstfocusIndex = preItems.findIndex((item) => item.element.tagName === 'INPUT');
  firstfocusIndex = firstfocusIndex === -1 ? 0 : firstfocusIndex;
  const newMenu = new Menu(menuElement.parentElement, menuType, [...preItems, ...items, ...postItems], firstfocusIndex);
  menus[newMenu.menuId] = newMenu;
  return newMenu;
}

function getMenuLevel(nestedUl) {
  const parentClassList = nestedUl.parentElement.classList;
  if (parentClassList.contains('level2Menu')) {
    return 'level2Menu';
  } else if (parentClassList.contains('level3Menu')) {
    return 'level3Menu';
  }
  return null;
}

//Classes
export class MainNav {
  constructor(mainNavDiv) {
    this.mainNavDiv = mainNavDiv;
    const { mainMenu, menus } = initializeMenu(mainNavDiv.querySelector('ul#main-menu'));
    this.mainMenu = mainMenu;
    this.menus = menus;
  }
}

export class MicrositeNav {
  constructor(micrositeNavDiv) {
    this.micrositeNavDiv = micrositeNavDiv;
    const { mainMenu, menus } = initializeMicrositeMenu(micrositeNavDiv.querySelector('ul'));
    this.mainMenu = mainMenu;
    this.menus = menus;
  }
}
