export function setupMenuFilter(globalNavElement, menu) {
  const filterDiv = menu.querySelector('.menu-filter');
  if (!filterDiv) return;
  const filterInput = menu.querySelector('input[type="text"]');
  const numResultsMsg = menu.querySelector('.number-of-results');
  const menuItems = menu.querySelectorAll('ul li a');
  const menuUl = menu.querySelector('ul');
  const filterClearBtn = filterDiv.querySelector('button');
  const debouncedFilterMenuItems = debounce(
    (event) => filterMenuItems(event, menu, filterInput, menuItems, numResultsMsg, globalNavElement),
    250
  );
  const debouncedScrollHandler = debounce(() => handleScroll(menuUl, filterDiv), 250);

  filterInput.addEventListener('keydown', debouncedFilterMenuItems);
  menuUl.addEventListener('scroll', debouncedScrollHandler);
  filterClearBtn.addEventListener('click', () => {
    clearMenuFilter(menu, globalNavElement);
    filterInput.focus();
  });
}

function filterMenuItems(event, menu, filterInput, menuItems, numResultsMsg, globalNavElement) {
  const filterClearBtn = menu.querySelector('.menu-filter button');
  if (event.key === 'Backspace' && filterInput.value === '') {
    filterClearBtn.click();
  }

  const filter = filterInput.value.toLowerCase().trim();
  if (filter.length > 0) {
    filterClearBtn.classList.remove('d-none');
  } else {
    filterClearBtn.classList.add('d-none');
  }
  menuItems.forEach((item) => {
    const text = item.textContent.toLowerCase();
    item.parentElement.style.display = text.includes(filter) ? 'block' : 'none';
  });

  const results = updateResultCount(menuItems, numResultsMsg);
  globalNavElement.setAttribute('data-filter-menu-id', menu.id);
  globalNavElement.setAttribute('data-filter-result-count', results);
  globalNavElement.setAttribute('data-filter-text', filter);
}

function debounce(func, delay) {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

function handleScroll(menuUl, filterDiv) {
  if (menuUl.scrollTop > 0) {
    filterDiv.classList.add('shadow-sm');
  } else {
    filterDiv.classList.remove('shadow-sm');
  }
}

export function clearMenuFilter(menu) {
  const filterInput = menu.querySelector('input[type="text"]');
  const numResultsMsg = menu.querySelector('.number-of-results');
  const menuItems = menu.querySelectorAll('ul li a');
  const filterClearBtn = menu.querySelector('.menu-filter button');

  if (!filterInput) return;
  filterInput.value = '';
  filterClearBtn.classList.add('d-none');
  menuItems.forEach((item) => {
    item.parentElement.style.display = 'block';
  });
  updateResultCount(menuItems, numResultsMsg);
}

function updateResultCount(menuItems, numResultsMsg) {
  const resultCount = Array.from(menuItems).filter((item) => item.parentElement.style.display !== 'none').length;
  numResultsMsg.textContent = `Showing ${resultCount} result${resultCount !== 1 ? 's' : ''}${
    resultCount === 0 ? ', clear text to see all results.' : ''
  }`;
  return resultCount;
}
