import { MicrositeNav } from './globalNav/a11yFunctions';

document.addEventListener('DOMContentLoaded', () => {
  initMicrositeNav();
});

function initMicrositeNav() {
  // Initialize the MicrositeNav class
  const micrositeNav = document.querySelector('nav.microsite');
  if (micrositeNav) {
    window.micrositeNav = new MicrositeNavigation(micrositeNav);
  }
}
class MicrositeNavigation {
  constructor(micrositeNav) {
    this.micrositeNavElement = micrositeNav.querySelector('.microsite-main-menu');
    this.mainMenuId = this.micrositeNavElement.id;
    this.micrositeMenu = new MicrositeNav(this.micrositeNavElement);
    this.mainNavHeight = 79 + 60;
    this.micrositeMenuOffcanvasBs = window.bootstrap.Offcanvas.getInstance(this.micrositeNavElement);
    this.micrositeToggleButton = micrositeNav.querySelector('.microsite-toggle');
    this.backdrop = micrositeNav.querySelector('.menu-page-backdrop');
    this.initializeEventListeners();
  }

  initializeEventListeners() {
    this.micrositeNavElement.addEventListener('hidden.bs.offcanvas', () => {
      this.hideAllLevel3Menus();
    });
    this.micrositeNavElement.addEventListener('hide.bs.offcanvas', () => {
      this.micrositeToggleButton.setAttribute('aria-expanded', 'false');
      this.micrositeToggleButton.classList.remove('open');
    });

    this.micrositeNavElement.addEventListener('show.bs.offcanvas', () => {
      this.micrositeMenuOffcanvasBs = window.bootstrap.Offcanvas.getInstance(this.micrositeNavElement);
      this.micrositeToggleButton.setAttribute('aria-expanded', 'true');
      this.micrositeToggleButton.classList.add('open');
    });

    this.micrositeNavElement.addEventListener('shown.bs.offcanvas', () => {
      this.micrositeMenu.menus[this.mainMenuId].navItems[
        this.micrositeMenu.menus[this.mainMenuId].firstFocusableElement
      ].element.focus();
    });

    this.backdrop.addEventListener('click', this.resetAllMicrositeMenus.bind(this));
    document.addEventListener('click', this.handleDocumentClick.bind(this));

    Object.keys(this.micrositeMenu.menus).forEach((menu) => {
      const menuObj = this.micrositeMenu.menus[menu];
      if (menuObj.menuType === 'microsite-menu') {
        menuObj.navItems.forEach((item) => {
          if (item.element.tagName === 'BUTTON') {
            item.element.addEventListener('click', () => {
              if (this.isCloseBtn(item) && this.micrositeMenuOffcanvasBs) {
                this.micrositeMenuOffcanvasBs.hide();
              } else {
                this.handleDropDownClick(menuObj, item);
              }
            });
          }
        });
        menuObj.menuElement.addEventListener('keydown', (e) => this.micrositeNavKeyboardA11y(e));
      } else if (menuObj.menuType === 'level3Menu') {
        menuObj.menuElement.addEventListener('keydown', (e) => this.micrositeLevel3KeyboardA11y(e, menuObj));
        menuObj.navItems.forEach((item) => {
          if (item.element.tagName === 'BUTTON') {
            item.element.addEventListener('click', () => {
              if (this.isCloseBtn(item)) {
                this.handleSubMenuCloseBtnClick(menuObj);
              } else if (this.isBackBtn(item)) {
                this.handleSubMenuBackBtnClick(menuObj);
              }
            });
          }
        });
      }
    });
  }

  handleDropDownClick(menuObj, item) {
    const parentUl = Array.from(menuObj.menuElement.children).find((child) => child.tagName === 'UL');
    const menuButton = item.element;
    const menu = this.micrositeMenu.menus[item.controls].menuElement;
    if (!menu.classList.contains('show')) {
      parentUl.classList.remove('overflow-scroll');
      menu.classList.remove('d-none');
      this.hideAllLevel3Menus();
      if (!isDesktop()) {
        menu.style.top = `-${menuButton.getBoundingClientRect().top - this.mainNavHeight}px`;
      }
      menu.classList.add('show');
      const firstFocusableElement =
        this.micrositeMenu.menus[menu.id].navItems[this.micrositeMenu.menus[menu.id].firstFocusableElement].element;
      if (firstFocusableElement.offsetParent !== null) {
        firstFocusableElement.focus();
      } else {
        while (this.micrositeMenu.menus[menu.id].getCurrentItem().element.offsetParent === null) {
          this.micrositeMenu.menus[menu.id].nextItem();
        }
        this.micrositeMenu.menus[menu.id].getCurrentItem().element.focus();
      }
      menuButton.setAttribute('aria-expanded', 'true');
      menuObj.navItems.forEach((item) => item.element.classList.remove('active'));
      menuButton.classList.add('active');
      menuButton.setAttribute('data-layer-label', `Open ${menuButton.innerText} Menu`);
    } else {
      menu.classList.remove('show');
      menu.classList.add('d-none');
      menuButton.classList.remove('active');
      menu.previousElementSibling.setAttribute('aria-expanded', 'false');
      menuButton.setAttribute('data-layer-label', `Close ${menuButton.innerText} Menu`);
      this.hideAllLevel3Menus();
    }
  }

  isCloseBtn(e) {
    return e.element.classList.contains('menu-close-btn');
  }

  isBackBtn(e) {
    return e.element.classList.contains('menu-back-btn');
  }

  handleDocumentClick(event) {
    Object.values(this.micrositeMenu.menus).forEach((menu) => {
      if (menu.menuType === 'level3Menu' && !menu.menuElement.previousElementSibling.contains(event.target)) {
        if (menu.menuElement.classList.contains('show')) {
          this.handleSubMenuCloseBtnClick(menu);
        }
      }
    });
  }

  handleSubMenuBackBtnClick(menuObj) {
    const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === menuObj.menuId);
    mainMenuNavItem.element.classList.remove('active');
    mainMenuNavItem.element.focus();
    this.hidelevel3Menu(menuObj, 200);
  }

  handleSubMenuCloseBtnClick(menuObj) {
    if (this.micrositeMenuOffcanvasBs) {
      this.micrositeMenuOffcanvasBs.hide();
    } else {
      this.hideMenuBackDrop();
    }
    this.hidelevel3Menu(menuObj, 200);
    const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === menuObj.menuId);
    mainMenuNavItem.element.classList.remove('active');
    mainMenuNavItem.element.focus();
  }

  hideAllLevel3Menus(delay = 0) {
    Object.values(this.micrositeMenu.menus).forEach((menu) => {
      if (menu.menuType === 'level3Menu') {
        if (menu.menuElement.classList.contains('show')) {
          this.hidelevel3Menu(menu, delay);
        }
      }
    });
  }

  hidelevel3Menu(menu, delay = 0) {
    menu.menuElement.classList.remove('show');
    menu.menuElement.previousElementSibling.setAttribute('aria-expanded', 'false');
    menu.resetMenu();
    setTimeout(() => {
      menu.menuElement.classList.add('d-none');
      menu.menuElement.style.top = '';
    }, delay);
  }

  showMenuBackDrop() {
    this.backdrop.classList.add('show');
    // want to make sure the backdrop is not scrollable when the menu is open
    document.body.style.overflow = 'hidden';
  }

  hideMenuBackDrop() {
    this.backdrop.classList.remove('show');
    // want to make sure the page is scrollable when the menu is closed
    document.body.style.overflow = '';
  }

  resetAllMicrositeMenus() {
    this.hideMenuBackDrop();
    this.hideAllLevel3Menus(200);
    this.micrositeMenu.mainMenu.resetMenu();
    this.micrositeMenu.mainMenu.navItems.forEach((navItem) => {
      navItem.element.classList.remove('active');
      navItem.element.setAttribute('aria-expanded', 'false');
    });
  }
  micrositeNavKeyboardA11y(e) {
    if (!isDesktop()) {
      const panelNav = this.micrositeMenu.mainMenu;
      if (e.code === 'Escape') {
        panelNav.navItems.find((item) => item.element.classList.contains('menu-close-btn')).element.click();
      } else if (e.code === 'Tab') {
        let item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
        while (item.element.offsetParent === null) {
          item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
        }
        if (item) item.element.focus();
      }

      if (e.code === 'Escape' || e.code === 'Tab') {
        e.stopPropagation();
        e.preventDefault();
      }
    }
  }

  micrositeLevel3KeyboardA11y(e, panelNav) {
    if (isDesktop()) {
      this.micrositeLevel3DesktopKeyboardA11y(e, panelNav);
    } else {
      if (e.code === 'Escape') {
        if (this.micrositeMenuOffcanvasBs) {
          this.micrositeMenuOffcanvasBs.hide();
        } else {
          this.hideMenuBackDrop();
        }
        this.hidelevel3Menu(panelNav, 200);
        const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === panelNav.menuId);
        mainMenuNavItem.element.classList.remove('active');
        mainMenuNavItem.element.focus();
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      if (e.code !== 'Tab') return;
      if (e.target.tagName === 'INPUT' && e.target.value) {
        e.target.nextElementSibling.focus();
      } else {
        let item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
        while (item.element.offsetParent === null || item.element.parentElement.style.display === 'none') {
          item = e.shiftKey ? panelNav.prevItem() : panelNav.nextItem();
        }
        if (item) item.element.focus();
      }

      e.stopPropagation();
      e.preventDefault();
    }
  }

  micrositeLevel3DesktopKeyboardA11y(e, panelNav) {
    if (e.code === 'Escape') {
      if (this.micrositeMenuOffcanvasBs) {
        this.micrositeMenuOffcanvasBs.hide();
      } else {
        this.hideMenuBackDrop();
      }
      this.hidelevel3Menu(panelNav, 200);
      const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === panelNav.menuId);
      mainMenuNavItem.element.classList.remove('active');
      mainMenuNavItem.element.focus();
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowDown') {
      let item = panelNav.nextItem();
      while (item.element.offsetParent === null || item.element.parentElement.style.display === 'none') {
        item = panelNav.nextItem();
      }
      if (item) item.element.focus();
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      let item = panelNav.prevItem();
      while (item.element.offsetParent === null || item.element.parentElement.style.display === 'none') {
        item = panelNav.prevItem();
      }
      if (item) item.element.focus();
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowLeft') {
      this.hidelevel3Menu(panelNav, 200);
      const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === panelNav.menuId);
      mainMenuNavItem.element.classList.remove('active');
      let sibling = mainMenuNavItem.element.parentElement.previousElementSibling;

      if (sibling && sibling.tagName !== 'UL') {
        sibling.children[0].focus();
        e.stopPropagation();
        e.preventDefault();
      }
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowRight') {
      this.hidelevel3Menu(panelNav, 200);
      const mainMenuNavItem = this.micrositeMenu.mainMenu.navItems.find((item) => item.controls === panelNav.menuId);
      mainMenuNavItem.element.classList.remove('active');
      let sibling = mainMenuNavItem.element.parentElement.nextElementSibling;

      if (sibling && sibling.tagName !== 'UL') {
        sibling.children[0].focus();
        e.stopPropagation();
        e.preventDefault();
      }
      e.stopPropagation();
      e.preventDefault();
      return;
    }
  }
}

const isDesktop = () => window.innerWidth >= 1200;
