const zoomElements = document.getElementsByClassName('zoom-trigger');

if (zoomElements.length > 0) {
  document.addEventListener('scroll', handleScroll);
}

function handleScroll() {
  Array.from(zoomElements).forEach(updateElementScale);
}

function updateElementScale(element) {
  const rect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  const isElementVisible = rect.top <= windowHeight && rect.bottom >= 0;

  if (isElementVisible) {
    // Calculate scale based on scroll position
    const scrollPosition = window.scrollY || window.pageYOffset;
    const scale = Math.min(1, 0.3 + scrollPosition / 1000);

    applyTransform(element, scale);
  } else {
    applyTransform(element, 0.5);
  }
}

function applyTransform(element, scale) {
  element.style.transform = `matrix(${scale}, 0, 0, ${scale}, 0, 0)`;
}
