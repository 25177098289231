export class FocusTrap {
  constructor(config) {
    this._config = this._getConfig(config); // Validate and store configuration options
    this._isActive = false; // Keeps track of whether focus trapping is active
    this._lastTabNavDirection = null; // Stores last tab navigation direction (forward/backward)
  }

  /**
   * Activates the focus trap
   * - Sets up event listeners for focus and keydown (Tab navigation)
   * - If autofocus is enabled, focuses on the trap element
   */
  activate() {
    const { trapElement, autofocus } = this._config;

    if (this._isActive) {
      return; // Prevent multiple activations
    }

    if (autofocus && trapElement) {
      trapElement.focus(); // Automatically focus the main trap element if configured
    }

    // Add event listeners to trap focus within the element
    document.addEventListener('focusin', this._handleFocusin.bind(this));
    document.addEventListener('keydown', this._handleKeydown.bind(this));

    this._isActive = true;
  }

  /**
   * Deactivates the focus trap
   * - Removes event listeners
   */
  deactivate() {
    if (!this._isActive) {
      return; // No need to remove listeners if already inactive
    }

    this._isActive = false;
    document.removeEventListener('focusin', this._handleFocusin.bind(this));
    document.removeEventListener('keydown', this._handleKeydown.bind(this));
  }

  /**
   * Handles focus events
   * - Ensures focus remains inside the trap element
   * - Redirects focus to the first or last focusable element when necessary
   */
  _handleFocusin(event) {
    const { target } = event;
    const { trapElement } = this._config;

    if (!trapElement || trapElement.contains(target)) {
      return; // Allow normal focus behavior if inside trap element
    }

    // Get all focusable elements inside the trap element
    const focusableElements = this._getFocusableElements(trapElement);

    if (focusableElements.length === 0) {
      trapElement.focus(); // No focusable elements, fallback to main trap element
    } else if (this._lastTabNavDirection === 'backward') {
      // Move focus to the last focusable element when Shift+Tab is pressed
      setTimeout(() => {
        focusableElements[focusableElements.length - 1].focus();
      }, 0);
    } else {
      // Move focus to the first focusable element otherwise
      focusableElements[0].focus();
    }
  }

  /**
   * Handles keydown events (Tab navigation)
   * - Detects whether the user is tabbing forward or backward
   */
  _handleKeydown(event) {
    if (event.key !== 'Tab') {
      return;
    }

    this._lastTabNavDirection = event.shiftKey ? 'backward' : 'forward';
  }

  /**
   * Validates and merges the user-provided configuration with default settings
   * @param {Object} config - User configuration
   * @returns {Object} - Merged configuration
   */
  _getConfig(config) {
    const defaultConfig = { trapElement: null, autofocus: true };
    return { ...defaultConfig, ...(typeof config === 'object' ? config : {}) };
  }

  /**
   * Retrieves all focusable elements within a container, including elements inside shadow DOMs
   * @param {HTMLElement | ShadowRoot} container - The container element
   * @returns {HTMLElement[]} - List of focusable elements
   */
  _getFocusableElements(container) {
    const focusableElements = [];
    const selectors = ['a[href]', 'button', 'textarea', 'input', 'select', '[tabindex]:not([tabindex="-1"])'];

    // Create a TreeWalker to efficiently traverse through the DOM
    const walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT, {
      acceptNode: (node) => {
        // Check if node is a standard focusable element
        if (node.matches(selectors.join(', ')) && !node.hasAttribute('disabled') && !node.getAttribute('aria-hidden')) {
          return NodeFilter.FILTER_ACCEPT;
        }

        // Include custom elements prefixed with "MHECOMM-" (shadow DOM handling)
        if (node.tagName.startsWith('MHECOMM-')) {
          return NodeFilter.FILTER_ACCEPT;
        }

        return NodeFilter.FILTER_SKIP; // Ignore non-focusable elements
      },
    });

    // Traverse the DOM to collect focusable elements
    while (walker.nextNode()) {
      focusableElements.push(walker.currentNode);

      // If a custom element contains a shadowRoot, fetch focusable elements inside it
      if (walker.currentNode.tagName.startsWith('MHECOMM-') && walker.currentNode.shadowRoot) {
        focusableElements.push(...this._getFocusableElements(walker.currentNode.shadowRoot));
      }
    }

    return focusableElements;
  }
}
